import React from 'react'
import { Capacitor } from '@capacitor/core'
import { InstallReferrer } from '~/plugin/InstallReferer'

const CampaignInit = () => {
  const setCampaignDataInLocalStorage = async () => {
    const referrerUrl = (await InstallReferrer.getReferrerDetails())?.referrerUrl
    console.log('referrerUrl', referrerUrl)

    localStorage.setItem('campaign_utm_source', referrerUrl)
    localStorage.setItem('isFirstLaunch', 'true')
  }

  React.useEffect(() => {
    if (Capacitor.getPlatform() === 'android' && !localStorage.getItem('isFirstLaunch')) {
      setCampaignDataInLocalStorage()
    }
  }, [])

  return null
}

export default CampaignInit
