import React from 'react'

interface FR8IconProps {
  /** SVG icon style class name */
  className?: string
}

/**
 * FR8 logo svg
 * Usage 1. Loads pwa bottom menu
 * @param props - FR8IconProps
 * @returns ReactJsxElement -> FR8Svg Icon
 */

export const Fr8LogoSvg: React.FC<FR8IconProps> = (props) => {
  const { className } = props
  const _class = className || 'w-10 h-10'
  return (
    <svg
      className={_class}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 247 247"
      fill="none">
      <path
        d="M159.666 114.112C219.979 71.0413 163.037 17.4713 127.449 20.0926V53.425C162.663 61.6665 146.554 93.1341 127.449 94.6325V137.713C156.219 143.407 158.417 162.812 155.92 171.803L182.517 200.646C215.184 159.888 180.894 126.353 159.666 114.112Z"
        fill="#144366"
      />
      <path
        d="M176.523 207.026L152.174 179.304C127.899 203.28 103.599 189.294 94.4831 179.304L70.5078 207.026C100.477 235.204 152.174 229.877 176.523 207.026Z"
        fill="#144366"
      />
      <path
        d="M87.3379 114.112C27.0251 71.0413 83.9664 17.4713 119.555 20.0926V53.425C84.341 61.6665 100.449 93.1341 119.555 94.6325V137.713C90.7844 143.407 88.5866 162.812 91.0841 171.803L64.4865 200.646C31.8202 159.888 66.1098 126.353 87.3379 114.112Z"
        fill="#008DD8"
      />
    </svg>
  )
}
